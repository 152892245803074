import React from 'react';
import tw from 'twin.macro';

const Unauthorized = () => {
  return (
    <div css={[tw`px-4 flex-col mt-8 w-full md:px-16 mx-auto items-center`]}>
      <div css={[tw`mb-6`]}>
        <h1 css={['font-family: "LatoBold";', tw`text-2xl text-center`]}>
          Sorry, you do not have access to this application
        </h1>
      </div>
    </div>
  );
};

export default Unauthorized;
